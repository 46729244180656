<template>
  <div class="home">
    <div class="banner">
      <el-carousel :interval="3000" height="731px">
        <el-carousel-item v-for="(item, index) in carousel" :key="index">
          <img class="img" :src="item" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="title">
      <img class="left" :src="require('@/assets/img/tit_left.png')" width="74" height="68" />
      <div class="text">长者主动健康教育</div>
      <img class="right" :src="require('@/assets/img/tit_right.png')" width="74" height="68" />
    </div>

    <div class="entrance1">
      <div class="card1" @click="openUrl(1)">
        <div class="card_text">
          <div class="chs">老年大学入口</div>
          <div class="en">Entrance to Senior University</div>
        </div>
        <div class="ikon ikon1"></div>
      </div>
      <div class="card2" @click="openUrl(2)">
        <div class="card_text">
          <div class="chs">教育学院入口</div>
          <div class="en">Entrance to Education College</div>
        </div>
        <div class="ikon ikon2"></div>
      </div>
      <div class="card3" @click="openUrl(3)">
        <div class="card_text">
          <div class="chs">儿童与长者脑健康训练平台</div>
          <div class="en">Children and Elderly Brain Health Training Platform</div>
        </div>
        <div class="ikon ikon3"></div>
      </div>
    </div>

    <div class="bgwhite">
      <div class="title">
        <img class="left" :src="require('@/assets/img/tit_left.png')" width="74" height="68" />
        <div class="text">O2O全案运营</div>
        <img class="right" :src="require('@/assets/img/tit_right.png')" width="74" height="68" />
      </div>
      <div class="entrance2">
        <div class="item" v-for="(item, index) in entrance2" :key="index" @mouseover="item.hover = true" @mouseout="item.hover = false" :style="{ background: item.hover ? `linear-gradient(${item.color1}, ${item.color2})` : '' }" @click="openUrl(index + 4, item)">
          <div class="icon" :style="{ background: item.hover ? `linear-gradient(${item.color1}, ${item.color2})` : '' }">
            <img :src="item.hover ? item.icon1 : item.icon2" :width="item.w" :height="item.h" />
          </div>
          <div class="txt" :style="{ color: item.hover ? '#fff' : '#171717' }">{{ item.text }}</div>
          <div class="go" :style="{ borderColor: item.hover ? '#fff' : item.color1, color: item.hover }">
            <span>GO</span>
            <img :src="item.arrow" width="10" height="18" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carousel: [],
      entrance2: [
        { hover: false, w: "54", h: "55", color1: "#FB7E39", color2: "#F59C27", text: "研发中心入口", icon1: require("../assets/img/icon1.png"), icon2: require("../assets/img/icon1ac.png"), arrow: require("../assets/img/arr1.png") },
        { hover: false, w: "54", h: "51", color1: "#0067D7", color2: "#008AF3", text: "供应链孵化入口", icon1: require("../assets/img/icon2.png"), icon2: require("../assets/img/icon2ac.png"), arrow: require("../assets/img/arr2.png") },
        { hover: false, w: "47", h: "55", color1: "#3ABB82", color2: "#05D293", text: "私人域流量建设入口", icon1: require("../assets/img/icon3.png"), icon2: require("../assets/img/icon3ac.png"), arrow: require("../assets/img/arr3.png") },
        { hover: false, w: "51", h: "50", color1: "#A825FF", color2: "#CD57FF", text: "公域流量矩阵", icon1: require("../assets/img/icon4.png"), icon2: require("../assets/img/icon4ac.png"), arrow: require("../assets/img/arr4.png") },
        { hover: false, w: "54", h: "54", color1: "#FF489C", color2: "#FF70BC", text: "品牌IP全网运营系统入口", icon1: require("../assets/img/icon5.png"), icon2: require("../assets/img/icon5ac.png"), arrow: require("../assets/img/arr5.png") },
      ],
    }
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    getList() {
      this.$axios
        .get(this.$api.pageList, {
          params: {
            bizType: 1, // 业务类型：1官网；2脑健康小程序
            page: 1,
            pageSize: 3,
          },
        })
        .then(res => {
          let list = res.result.list
          this.carousel = list.map(item => item.contentUrl)
        })
        .catch(err => {
          this.$message.error(err.desc)
        })
    },
    openUrl(id) {
      this.$confirm("系统研发中...", "敬请期待", {
        type: "",
        confirmButtonText: "确定",
      })
        .then(() => {})
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="scss">
.home {
  height: 100%;
  background: linear-gradient(90deg, #f6f9ff, #e2ecff);

  .banner {
    ::v-deep .el-carousel__indicators--horizontal {
      margin-bottom: 40px;

      .el-carousel__button {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #fff;
      }

      .is-active .el-carousel__button {
        background-color: $themes;
      }
    }

    .img {
      width: 100%;
      max-width: 1920px;
      height: 731px;
      object-fit: cover;
    }
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 215px;

    .text {
      color: #171717;
      font-size: 46px;
    }

    .left {
      transform: translate(40%, 20%);
    }

    .right {
      transform: translate(-40%, -20%);
    }
  }

  .entrance1 {
    display: flex;
    justify-content: space-around;
    padding: 40px 40px 60px;

    .card1 {
      background: url(../assets/img/ent1bg.png) no-repeat;
    }

    .card2 {
      background: url(../assets/img/ent2bg.png) no-repeat;
    }

    .card3 {
      background: url(../assets/img/ent3bg.png) no-repeat;
    }

    .card1,
    .card2,
    .card3 {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 50px;
      margin-bottom: 40px;
      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        opacity: 0.6;
      }

      .card_text {
        color: #171717;

        .chs {
          font-size: 36px;
          font-weight: bold;
          margin-bottom: 12px;
        }

        .en {
          font-size: 22px;
        }
      }
    }

    .card1,
    .card2 {
      width: 545px;
      height: 200px;
      background-size: contain;
    }

    .card3 {
      width: 613px;
      height: 200px;
      background-size: contain;

      .card_text {
        .chs {
          font-size: 30px;
        }

        .en {
          font-size: 16px;
        }
      }
    }

    .ikon {
      position: absolute;
      top: -26px;
      right: 20px;
      height: 150px;
    }

    .ikon1 {
      width: 137px;
      background: url(../assets/img/ent1ikon.png) no-repeat;
    }

    .ikon2 {
      width: 179px;
      background: url(../assets/img/ent2ikon.png) no-repeat;
    }

    .ikon3 {
      width: 142px;
      background: url(../assets/img/ent3ikon.png) no-repeat;
    }
  }

  @media screen and (min-width: 1681px) and (max-width: 1919px) {
    .entrance1 {
      flex-wrap: nowrap;
    }
  }

  @media screen and (max-width: 1680px) {
    .entrance1 {
      flex-wrap: wrap;
    }
  }

  .bgwhite {
    padding-bottom: 100px;
    background-color: #fff;

    .title {
      padding: 0 0 100px;
    }
  }

  .entrance2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 40px;

    .item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 336px;
      height: 302px;
      padding: 100px 40px 50px;
      margin-bottom: 90px;
      text-align: center;
      background-color: #f7f8ff;
      border-radius: 10px;
      box-sizing: border-box;
      transition: background 0.5s ease;
      cursor: pointer;

      .icon {
        position: absolute;
        top: -60px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        border: 5px solid #fff;
        border-radius: 50%;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        box-sizing: border-box;
        background-color: #fff;
      }

      .txt {
        color: #171717;
        font-size: 26px;
        font-weight: bold;
      }

      .go {
        width: 130px;
        height: 45px;
        line-height: 45px;
        font-size: 26px;
        font-weight: bold;
        text-align: center;
        background-color: #fff;
        border-radius: 50px;
        border: 2px solid #fff;

        span {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
